<template>
  <section id="patient-dashboard">
    <div class="breadcrumbs mb-3 ml-3">
      <router-link to="/centro-cirurgico/lista-pacientes" tag="span" class="link">
        Centro cirúrgico
      </router-link>
      <ChevronRight class="icon"/>
      <span v-if="!loading">{{ surgery?.appointment?.patient?.name }}</span>
      <b-skeleton v-else type="text" width="200px" class="mb-0" />
    </div>
    <b-container v-if="!loading" fluid>
      <b-row>
        <b-col cols="4">
          <PatientCard
            :patient="surgery?.appointment?.patient"
            :clinicId="clinic.id"
            :buttonText="getActionBtnText"
            @onButtonAction="getButtonAction"
          />
          <CompanionContainer
            :surgeryInformationId="surgery.id"
            :patientId="surgery?.appointment?.patient?.id"
          />
          <!-- <ProductsContainer :surgeryId="surgery.id" /> -->
          <SurgeryFiles
            :surgeryId="surgery.id"
            :patientId="surgery?.appointment?.patient?.id"
            @reload-files="getSurgeryFiles"
          />
        </b-col>

        <b-col class="mw-666">
          <b-row>
            <b-col>
              <SurgeryStatusUpdate :surgery="surgery" />
            </b-col>
            <b-col>
              <PrintSurgeryFiles :surgeryId="surgery.id" :fileGroup="files"/>
            </b-col>
          </b-row>

          <SurgeryData :surgery="surgery" />

          <AdditionalInfoContainer
            :surgeryInformationId="surgeryId"
            :patientId="surgery?.appointment?.patient?.id"
            @generalSheetsUpdated="getSurgeryFiles"
          />
        </b-col>
      </b-row>
    </b-container>
    <Skeleton v-else />
  </section>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager.js'

export default {
  name: 'PatientDashboard',

  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      patient: {},
      surgery: {},
      surgeryAttendance: null,
      anesthesistAttendance: null,
      teamEvolutionAttendance: null,
      teamAnnotationsAttendance: null,
      files: {},

      parseType: {
        'SURGERY': 'Consulta cirúrgica',
        'ANESTHETIST_EVOLUTION': 'Evolução do anestesista',
        'TEAM_EVOLUTION': 'Evolução da equipe multidisciplinar',
        'TEAM_ANNOTATIONS': 'Anotações da equipe multidisciplinar',
      },

      surgeryId: this.$route.params.surgeryId,
      selectedFile: null,

      loading: false,
    }
  },
  components: {
    Skeleton: () => import('./DashboardSkeleton.vue'),
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    PatientCard: () => import('@/components/Dashboard/PatientCard'),
    SurgeryStatusUpdate: () => import('@/components/SurgeryCenter/Dashboard/SurgeryStatusUpdate.vue'),
    PrintSurgeryFiles: () => import('@/components/SurgeryCenter/Dashboard/PrintSurgeryFiles.vue'),
    CompanionContainer: () => import('@/components/SurgeryCenter/Dashboard/CompanionContainer.vue'),
    // ProductsContainer: () => import('@/components/SurgeryCenter/Dashboard/ProductsContainer.vue'),
    SurgeryFiles: () => import('@/components/SurgeryCenter/Dashboard/SurgeryFiles.vue'),
    SurgeryData: () => import('@/components/SurgeryCenter/Dashboard/SurgeryData.vue'),
    AdditionalInfoContainer: () => import('@/components/SurgeryCenter/Dashboard/AdditionalInfoContainer'),
  },
  async created () {
    this.loading = true
    await this.getSurgery()
    await this.getSurgeryFiles()
    this.loading = false
  },
  computed: {
    getActionBtnText () {
      switch (this.user.type) {
        case 'DOCTOR':
        case 'DOCTOR_MANAGER':
          if (!this.surgeryAttendance) 
            return 'Iniciar cirurgia'
          else 
            return this.surgeryAttendance?.status === 'OPENED' ? 'Continuar cirurgia' : 'Visualizar cirurgia'
        case 'DOCTOR_ANESTHETIST':
          if (!this.anesthesistAttendance || this.anesthesistAttendance?.status === 'OPENED') 
            return 'Adicionar evolução'
          else 
            return  'Visualizar evolução'
        case 'NURSE':
          if (!this.teamEvolutionAttendance || this.teamEvolutionAttendance?.status === 'OPENED') 
            return 'Adicionar evolução'
          else 
            return  'Visualizar evolução da equipe'
        case 'TECHNICIAN':
        case 'TECHNOLOGIST':
          if (!this.teamAnnotationsAttendance || this.teamAnnotationsAttendance?.status === 'OPENED') 
            return 'Adicionar anotação'
          else 
            return 'Visualizar anotações da equipe'
        default:
          return null
      }
    },
  },
  methods: {
    async getSurgery () {
      try {
        const res = await this.api.getSurgery(this.surgeryId)
        this.surgery = res.data
        this.getSurgeryAttendance()
        this.getAnesthetistAttendance()
        this.getTeamEvolutionAttendance()
        this.getTeamAnnotationsAttendance()
      } catch (error) {
        this.$toast.error('Erro ao buscar informações da cirurgia, contate o suporte se o erro persistir.')
      }
    },
    async getSurgeryFiles() {
      try {
        const { data } = await this.api.getSurgeryPrintableFiles(this.surgeryId)
        this.files = Object.keys(data)?.length ? data : {}
      } catch (error) {
        this.$toast.error('Não foi possível obter os arquivos da cirurgia, contate o suporte se o erro persistir.')
      }
    },
    async getForm(type) {
      try {
        const response = await this.api.getForms(this.clinic.id, this.user.id, type);
        const form = response.data.find(f => f?.name === this.parseType[type]);
        return form?.id
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getButtonAction() {
      switch (this.user.type) {
        case 'DOCTOR':
        case 'DOCTOR_MANAGER':
          return this.handleStartSurgery()
        case 'DOCTOR_ANESTHETIST':
          return this.handleStartAnesthetistEvolution()
        case 'NURSE':
        case 'TECHNICIAN':
        case 'TECHNOLOGIST':
          return this.handleStartTeamEvolution()
        default:
          return null
      }
    },
    getSurgeryAttendance () {
      this.surgeryAttendance = this.surgery?.appointment?.attendance.find(
        attendance => attendance.type === 'SURGERY'
      )
    },
    getTeamEvolutionAttendance () {
      this.teamEvolutionAttendance = this.surgery?.appointment?.attendance.find(
        attendance => attendance.type === 'TEAM_EVOLUTION'
      )
    },
    getTeamAnnotationsAttendance () {
      this.teamAnnotationsAttendance = this.surgery?.appointment?.attendance.find(
        attendance => attendance.type === 'TEAM_ANNOTATIONS'
      )
    },
    getAnesthetistAttendance () {
      this.anesthesistAttendance = this.surgery?.appointment?.attendance.find(
        attendance => attendance.type === 'ANESTHETIST_EVOLUTION'
      )
    },
    handleStartSurgery () {
      if (this.surgeryAttendance) 
        this.$router.push(`/pacientes/${this.surgery.appointment.patient_id}/cirurgia/${this.surgeryAttendance.id}/${this.surgeryId}`)
      else 
        this.startAttendance('SURGERY', 'Em cirurgia', 'cirurgia')
    },
    handleStartAnesthetistEvolution () {
      if (this.anesthesistAttendance) 
        this.$router.push(`/pacientes/${this.surgery.appointment.patient_id}/evolucao-anestesista/${this.anesthesistAttendance.id}/${this.surgeryId}`)
      else 
        this.startAttendance('ANESTHETIST_EVOLUTION', null, 'evolucao-anestesista')
    },
    handleStartTeamEvolution () {
      if (this.teamEvolutionAttendance) 
        this.$router.push(`/pacientes/${this.surgery.appointment.patient_id}/evolucao-equipe/${this.teamEvolutionAttendance.id}/${this.surgeryId}`)
      else 
        this.startAttendance('TEAM_EVOLUTION', null, 'evolucao-equipe')
    },
    handleStartTeamAnnotation () {
      if (this.teamAnnotationAttendance) 
        this.$router.push(`/pacientes/${this.surgery.appointment.patient_id}/anotacoes-equipe/${this.teamAnnotationAttendance.id}/${this.surgeryId}`)
      else 
        this.startAttendance('TEAM_ANNOTATIONS', null, 'anotacoes-equipe')
    },
    async startAttendance (attendanceType, surgeryStatus, route) {
      const isLoading = this.$loading.show()
      try {
        const formId = await this.getForm(attendanceType)
        const res = await this.api.startAttendance(this.clinic.id, this.surgery.appointment.patient_id, this.surgery.appointment_id, formId, attendanceType)
       
        if (surgeryStatus) 
          this.api.updateSurgeryInformation(this.surgery.id, { status: surgeryStatus })
        
        this.$router.push(`/pacientes/${this.surgery.appointment.patient_id}/${route}/${res.data.id}/${this.surgeryId}`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
  },
}
</script>

<style lang="scss">
#patient-dashboard {
  height: 100%;
  padding: 30px 24px;

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 16px;

    .icon {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .item-header {
    .header-title {
      font-weight: 700 !important;
      font-size: min(1.2vw, 16px) !important;
      color: var(--dark-blue) !important;
    }
  }
  .mw-666 {
    max-width: 66.6%;
  }
}
</style>